/*
    Be aware that SponsoredOfferBox component relies heavily on the layout of this component.
    In case of making any change in layout, please also update SponsoredOfferBox

    TODO: Maybe it's worth to make some abstraction/shared component if there are more cases like this
 */
import React, {memo, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Badge} from "@pg-design/badge";
import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, p, pointer, pr, truncate, truncateMultiline, w100} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Highlight} from "@pg-design/text-module";
import {rpAppLink} from "@pg-mono/rp-routes";
import {dateTimeFormat, formatFutureDate, numberFormat} from "@pg-mono/string-utils";

import {IRPStore} from "../../../app/rp_reducer";
import {OfferBoxBase} from "../../../atoms/OfferBoxBase";
import {DesktopText} from "../../../atoms/typography/DesktopText";
import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";
import {useResponsiveOpenLink} from "../../../hooks/use_responsive_open_link";
import {Country} from "../../../region/types/Country";
import {IRangeQuery} from "../../../request/IRangeQuery";
import {showPropertiesClick} from "../../../tracking/algolytics_hits/show_properties_click";
import {Currency} from "../../../types/Currency";
import {getCurrency} from "../../../utils/get_currency";
import {FavouriteIcon} from "../../atoms/FavouriteIcon";
import {distanceFromFormat} from "../../atoms/OfferUVP";
import {useOfferLink} from "../../detail/hooks/use_offer_link";
import {IOfferGroupsSimplified} from "../../detail/types/IOfferGroups";
import {getOfferTypeNamePlural} from "../../helpers/OfferType";
import {OfferPriceType} from "../../types/OfferPriceType";
import {OfferType} from "../../types/OfferType";
import {getOfferAddressText} from "../../utils/get_offer_address_text";
import {getOfferDistanceFromRegion} from "../../utils/get_offer_distance_from_region";
import {ArchivedBadge} from "./ArchivedBadge";
import {OfferToggleDescription} from "./OfferToggleDescription";
import {PrimaryBadge} from "./PrimaryBadge";

const presaleOfferDefaultImg = require("../../images/presale_offer_default_img_375x212.jpg");

export interface IOfferBox {
    isArchived?: boolean;
    offer: IOfferBoxOffer;
    onShowOfferDetailsBtnClick?: (offer: IOfferBoxOffer) => void;
    offerDetailsBtnText?: string;
    unlinkVendor?: boolean;
    disableFavInfoText?: boolean;
    className?: string;
    shouldLinkToOffer?: boolean;
    onClick?: () => void;
    index?: number;
    showFullPrice?: boolean;
    fetchPriority?: "high" | "low" | "auto";
}

export interface IOfferBoxOffer {
    construction_date_range: IRangeQuery<string>;
    configuration: {
        pre_sale?: boolean;
    };
    currency: Currency;
    description: string | null;
    distance_from_the_beach?: number | null;
    geo_point: {
        coordinates: [number, number];
    };
    groups: IOfferGroupsSimplified | null;
    has_active_promotions?: boolean;
    id: number;
    name: string;
    main_image?: {
        m_img_375x211: string;
        m_img_155x87: string;
    } | null;
    region: {
        country: Country;
        full_name: string;
        id: number;
        short_name_reverted: string | null;
    };
    slug: string;
    stats: {
        distance_from_region: {
            center: boolean;
            region: string;
            distance: number;
        };
        properties_count_for_sale: number;
        properties_with_roi_count: number;
        ranges_area_min: number;
        ranges_area_max: number;
        ranges_price_m2_min: number;
        ranges_price_min: number;
        ranges_rooms_max: number;
        ranges_rooms_min: number;
    } | null;
    street_name: string | null;
    street_number: string | null;
    type: OfferType;
    vendor: {
        id: number;
        name: string;
        slug: string;
        logo: {
            v_log_80x60?: string;
        } | null;
    };
    price_type?: number | null;
}

const VendorImageWrapper: React.FC<{children: React.ReactNode; unlinkVendor?: boolean; vendorLink: string | undefined; target?: string}> = (props) => {
    if (props.unlinkVendor) {
        return <div>{props.children}</div>;
    }

    return (
        <a css={pointer} href={props.vendorLink} target={props.target}>
            {props.children}
        </a>
    );
};

const getOfferBoxBadge = (offer: IOfferBoxOffer) => {
    if (offer.has_active_promotions) {
        return (
            <Badge css={bottomBadge} variant="label_success">
                % <strong>Promocje</strong>
            </Badge>
        );
    }

    if (isOfferBoxConfiguration(offer.configuration) && offer.configuration.pre_sale) {
        return (
            <Badge css={bottomBadge} variant="label_success">
                <strong>Przedsprzedaż</strong>
            </Badge>
        );
    }

    return null;
};

export const OfferBox = memo((props: IOfferBox) => {
    const [isInfoPanelOpened, setIsInfoPanelOpened] = useState(false);
    const {offer, isArchived, showFullPrice, fetchPriority} = props;
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const offerLink = useOfferLink(props.offer);
    const vendorLink = rpAppLink.vendor.detail.base({vendorSlug: offer.vendor.slug, vendorId: offer.vendor.id});
    const openResponsiveOfferLink = useResponsiveOpenLink(offerLink);

    const onInfoPanelToggleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsInfoPanelOpened(!isInfoPanelOpened);
        event.preventDefault();
    };
    const onInfoPanelCloseClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsInfoPanelOpened(false);
    };

    const trackedOffer = {
        geo_point: {
            coordinates: offer.geo_point.coordinates
        },
        id: offer.id,
        name: offer.name,
        type: offer.type,
        region: {
            country: offer.region.country,
            full_name: offer.region.full_name,
            id: offer.region.id
        }
    };

    const target = useResponsiveLinkTarget();
    const distanceFrom = offer.distance_from_the_beach ? `${distanceFromFormat(offer.distance_from_the_beach)} od morza` : getOfferDistanceFromRegion(offer);
    const addressText = getOfferAddressText(offer);
    const mainImage =
        offer.main_image?.m_img_375x211 || (isOfferBoxConfiguration(offer.configuration) && offer.configuration.pre_sale ? presaleOfferDefaultImg : undefined);
    const currency = getCurrency({currency: offer.currency});
    const priceType = offer.region.country === Country.SPAIN && offer.price_type === OfferPriceType.NETTO ? " netto" : "";

    return (
        <OfferBoxBase data-id={offer.id} className={props.className} onClick={props.onClick}>
            <div css={[offerBoxTopSection, pointer]} onClick={openResponsiveOfferLink}>
                <Image
                    css={boxTopRadius}
                    src={mainImage}
                    alt={offer.name}
                    width="375px"
                    height="211px"
                    ratio={{xs: "375/211"}}
                    loading={props.index === 0 ? undefined : "lazy"}
                    fetchPriority={fetchPriority}
                />

                {
                    isArchived ? (
                        <ArchivedBadge css={badge}>Oferta archiwalna</ArchivedBadge>
                    ) : offer.construction_date_range ? (
                        <PrimaryBadge css={badge}>{formatFutureDate(offer.construction_date_range.upper, dateTimeFormat.quarter)}</PrimaryBadge>
                    ) : null // Render nothing or a fallback component if construction_date_range is undefined
                }

                <div css={favouriteIcon}>
                    <FavouriteIcon
                        disableUntilMount
                        offerId={offer.id}
                        trackedOffer={trackedOffer}
                        trackedVendor={offer.vendor}
                        disableInfoText={props.disableFavInfoText}
                    />
                </div>

                {getOfferBoxBadge(props.offer)}

                {offer.description && (
                    <InfoIcon onClick={onInfoPanelToggleClick} css={infoIcon} size="1.3" wrapperType="circle" wrapperColor="white" wrapperSize="2.2" />
                )}
            </div>

            <div css={offerBoxInfoHolder}>
                <div>
                    {offer.description && (
                        <OfferToggleDescription show={!!offer.description && isInfoPanelOpened} onClose={onInfoPanelCloseClick}>
                            {offer.description}
                        </OfferToggleDescription>
                    )}

                    <div css={pointer}>
                        <a href={offerLink} title={offer.name} target={target} css={offerLinkStyle}>
                            <DesktopText variant="headline_6" as="h2" css={truncate} className="bt">
                                {offer.name}
                            </DesktopText>
                        </a>
                        <div onClick={openResponsiveOfferLink}>
                            {(((showFullPrice && offer.stats?.ranges_price_min) || offer.stats?.ranges_price_m2_min) ?? 0) > 0 ? (
                                <DesktopText variant="body_copy_2" as="div" strong>
                                    <Highlight>
                                        od{" "}
                                        <span className="bt">
                                            {showFullPrice && offer.stats?.ranges_price_min ? (
                                                `${numberFormat(offer.stats?.ranges_price_min ?? 0)} ${currency} ${priceType}`
                                            ) : (
                                                <>
                                                    {numberFormat(offer.stats?.ranges_price_m2_min ?? 0)} {currency}/m<sup>2</sup>
                                                </>
                                            )}
                                        </span>
                                    </Highlight>
                                </DesktopText>
                            ) : (
                                <NoTagPlug height="2rem" />
                            )}
                        </div>
                    </div>

                    <div css={addressAndLogoHolder}>
                        <div onClick={openResponsiveOfferLink} css={[pointer, pr()]}>
                            <DesktopText css={[() => truncateMultiline(2), noAddressTextPlug]} variant="body_copy_2" className="bt">
                                {addressText}
                            </DesktopText>

                            {distanceFrom ? (
                                <DesktopText variant="body_copy_2" strong>
                                    ({distanceFrom})
                                </DesktopText>
                            ) : (
                                <NoTagPlug height="2rem" />
                            )}
                        </div>

                        {offer.vendor.logo?.v_log_80x60 ? (
                            <VendorImageWrapper unlinkVendor={props.unlinkVendor} vendorLink={vendorLink} target={target}>
                                <Image alt={offer.vendor.name} src={offer.vendor.logo.v_log_80x60} width="80px" height="60px" />
                            </VendorImageWrapper>
                        ) : (
                            <NoTagPlug height="6.4rem" width="8rem" />
                        )}
                    </div>
                </div>
            </div>

            {props.onShowOfferDetailsBtnClick && !isArchived ? (
                <div css={[p(0, 2, 2, 2)]}>
                    <Button
                        css={ctaStyle}
                        variant="filled_primary"
                        onClick={() => {
                            if (props.shouldLinkToOffer) {
                                return;
                            }
                            viewType != null && showPropertiesClick(viewType, trackedOffer, props.offer.vendor);
                            props.onShowOfferDetailsBtnClick && props.onShowOfferDetailsBtnClick(props.offer);
                        }}
                        href={props.shouldLinkToOffer ? offerLink : undefined}
                        target={props.shouldLinkToOffer ? "_blank" : undefined}
                    >
                        {props.offerDetailsBtnText ?? `Zobacz ${getOfferTypeNamePlural(offer.type)}`}
                    </Button>
                </div>
            ) : (
                <NoTagPlug height="6.4rem" />
            )}
        </OfferBoxBase>
    );
});

//  Utils
function isOfferBoxConfiguration(offerConfiguration: object): offerConfiguration is {pre_sale?: boolean} {
    return "pre_sale" in offerConfiguration;
}

//  Styles
const INFO_PANEL_PADDING = calculateRemSize(1.5);

const badge = css`
    position: absolute;
    top: ${INFO_PANEL_PADDING};
    left: ${INFO_PANEL_PADDING};
`;

const bottomBadge = css`
    position: absolute;
    bottom: ${INFO_PANEL_PADDING};
    left: ${INFO_PANEL_PADDING};
`;

const favouriteIcon = css`
    position: absolute;
    top: ${INFO_PANEL_PADDING};
    right: ${INFO_PANEL_PADDING};
`;

const infoIcon = css`
    position: absolute;
    bottom: ${INFO_PANEL_PADDING};
    right: ${INFO_PANEL_PADDING};
    z-index: 5;
`;

export const offerBoxTopSection = css`
    display: block;
    position: relative;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
`;

const boxTopRadius = css`
    ${borderRadius(2, 2, 0, 0)};
`;

const offerLinkStyle = css`
    &:hover {
        color: unset;
    }
`;

const offerBoxInfoHolder = css`
    padding: 1.6rem 1.5rem 1.6rem;
    position: relative;
    min-height: 17.3rem;
`;

const addressAndLogoHolder = css`
    display: flex;
    padding-top: ${calculateRemSize(2)};
    justify-content: space-between;
    align-items: center;
    min-height: 6rem;
`;

// We want to keep the layout of the box offer same at all times, despite the fact it may be missing some data:
const NoTagPlug = styled.div<{height: string; width?: string}>`
    ${({height, width}) => css`
        height: ${height};
        ${width && `width: ${width}`}
    `}
`;

const noAddressTextPlug = css`
    height: 4rem;
`;

const ctaStyle = (theme: Theme) => css`
    ${w100};
    //TODO fix this important shit
    text-decoration: none !important;
    color: ${theme.colors.secondary} !important;
`;
